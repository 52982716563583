import $ from 'jquery';

import Carousel from './jquery.carousel';
import OpenMenu from './jquery.open-menu';
import Header from './jquery.header';
import Submenu from './jquery.submenu';

const mapDOMPluginElements = ($context, callback) => {
  const pluginList = [];

  $context.find('[class*="js-"]').each((index, element) => {
    const pluginClass = $(element)
      .attr('class')
      .match(/js-[\w-]*\b/)[0];
    const pluginName = pluginClass.replace('js-', '');
    if ($.fn[pluginName] && !pluginList.includes(pluginName)) {
      pluginList.push(pluginName);
      callback(pluginClass, pluginName);
    }
  });
};

export default {
  init($context) {
    const $ctx = $context || $('html');

    mapDOMPluginElements($ctx, (pluginClass, pluginName) => {
      $ctx.find(`.${pluginClass}`)[pluginName]();
    });
  },
  destroy($context) {
    const $ctx = $context || $('html');

    mapDOMPluginElements($ctx, (pluginClass, pluginName) => {
      $ctx.find(`.${pluginClass}`)[pluginName]('destroy');
    });
  }
};
