import $ from 'jquery';

import plugin from './plugin';

const pluginName = 'header';

class Header {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.$windows = $(window);
    this.$leng = this.$el.find('.c-button-menu__lang');
    this.$btnleng = this.$el.find('.js-lang');
    this.$btnleng.on('click', this.openLang.bind(this));
    this.$el.on('load', this.init());
  }

  init() {
    $('.c-header__title').addClass('c-header__title--hide');
    $('.c-header').addClass('c-header--hollow');
    this.$windows.scroll(function() {
      const scroll = $(this).scrollTop();

      if (scroll >= 500) {
        $('.c-header').removeClass('c-header--hollow');
        $('.c-header__title').removeClass('c-header__title--hide');
      } else {
        $('.c-header').addClass('c-header--hollow');
        $('.c-header__title').addClass('c-header__title--hide');
      }
    });
    return this;
  }

  openLang() {
    this.$leng.toggleClass('is-open');

    return this;
  }

  destroy() {
    return this;
  }
}

Header.DEFAULTS = {};

plugin(pluginName, Header);
