import $ from 'jquery';

import plugin from './plugin';

const pluginName = 'open-menu';

class OpenMenu {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.$nav = $('body').find('.c-nav');

    this.$el.on('click', this.openMenu.bind(this));
    /* this.$cover.on('click', this.openNav.bind(this)); */
  }

  openMenu() {
    this.$el.toggleClass('is-open');
    this.$nav.toggleClass('is-open is-started');

    return this;
  }

  destroy() {
    return this;
  }
}

OpenMenu.DEFAULTS = {};

plugin(pluginName, OpenMenu);
