import $ from 'jquery';
import WebFont from 'webfontloader';

import Utils from './utils';
import Plugins from './plugins';

// Expose jQuery Globally for Fuckin BOW-CMS
window.jQuery = $;

// Load Webfont Async
WebFont.load({
  google: {
    families: [
      'Dosis' // base

    ]
  }
});

Plugins.init();
