import $ from 'jquery';
import Swiper from 'swiper/js/swiper';

import plugin from './plugin';

const pluginName = 'carousel';

class Carousel {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;

    this.carouselInstance = null;
    this.setCarousel();
  }

  setCarousel() {
    this.carouselInstance = new Swiper(this.$el, {
      spaceBetween: 60,
      slidesPerView: 1,
      // setWrapperSize: true,
      loop: false,
      simulateTouch: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpointsInverse: true,
      breakpoints: {
        768: {
          slidesPerView: 2
        },
        1200: {
          slidesPerView: 3
        }
      }
    });

    if (
      window.screen.width >= 1200 &&
      this.carouselInstance.slides.length === 3
    ) {
      $('.swiper-button-next').addClass('d-none');
      $('.swiper-button-prev').addClass('d-none');
    } else {
      this.carouselInstance.simulateTouch = true;
    }
  }

  destroy() {
    return this;
  }
}

Carousel.DEFAULTS = {};

plugin(pluginName, Carousel);
