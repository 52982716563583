import $ from 'jquery';

import plugin from './plugin';

const pluginName = 'submenu';

class Submenu {
  constructor(element, options) {
    this.$el = $(element);
    this.options = options;
    this.$button = this.$el.siblings('span');

    this.$button.on('click', this.openSubmenu.bind(this));
  }

  openSubmenu() {
    const heightInner = this.$el.find('.c-submenu__inner').height();

    if (this.$el.hasClass('is-open')) {
      this.$el.removeClass('is-open').removeAttr('style');
    } else {
      this.$el.addClass('is-open').css('max-height', heightInner);
    }

    return this;
  }

  destroy() {
    return this;
  }
}

Submenu.DEFAULTS = {};

plugin(pluginName, Submenu);
